.navbar {
    width: 100%;
    background-color: black; /* Set the background color to black */
    color: white; /* Adjust text color for visibility */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center; /* This ensures vertical alignment */
  }
  
  .navbar-logo {
    max-height: 50px; /* Adjust based on your logo size */
  }
  
  h1 {
    margin-left: 10px; /* Adds some space between the logo and the text */
    font-size: 1.5rem; /* Adjust the font size as needed */
  }
  
  .link-container {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
  
  .marketplace-button {
    background-color: white; /* Set the background color to white */
    color: black; /* Set the text color to black */
    padding: 10px 20px; /* Add some padding around the text */
    text-decoration: none; /* Remove the underline from the link */
    border: 2px solid black; /* Optional: add a black border */
    border-radius: 25px; /* Rounded edges */
    font-weight: bold; /* Optional: make the text bold */
    display: inline-block; /* Necessary to apply padding and margins */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  }
  
  .marketplace-button:hover {
    background-color: rgb(222, 222, 222); /* Change background to black on hover */
  }
  .edge{

    margin: 5px
  }
  .logo-container {
    cursor: pointer;
  }
  