.container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content (including the list) horizontally */
  }
  
  .list-group {
    list-style-type: none; /* Keeps removing the bullet points */
    padding: 0; /* Removes the default padding */
    width: 100%; /* Adjust if you want a specific width for the list */
  }
  
  .image-container {
    text-align: center; /* This will center the images */
  }
  
  .centered-image {
    max-width: 100%; /* Adjusts the size of the image */
    width: 70%;
    height: auto; /* Maintains the aspect ratio */
    display: block; /* Treats the image as a block-level element */
    margin: 0 auto; /* Centers the image */
    border-radius: 5px; /* Rounds the corners of the image */
    border: 2px solid #000; /* Adds a solid black border around the image */
  }
  
  .image-container h4 {
    text-align: left; /* Aligns the text to the left within the centered image-container */
    width: 100%; /* Ensures the text takes full width for proper alignment */
  }
  