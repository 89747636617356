.delete-container {
    margin-top: 20px; /* Adjust the value as needed for the top margin */
  }
  
  #add h3 {
    color: #000; /* This will make the text black */
    font-size: 1.5em; /* Adjust the value to make the text bigger */
  }
.title{
    margin-top: 15px;
}  
.p{
    text-align: left;
    font-size: 25px;
}